import React from 'react'
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Nabvar from './components/Nabvar'
import Footer from './components/Footer'
import Username from './forms/Username'
import Password from './forms/Password'
import Home from './components/Home'

const App = () => {
  return (
    <BrowserRouter>
      <Nabvar/>
      <Routes>
        <Route path= "/home" element={<Home />}/>
        <Route path="/changeusername" element={<Username />} />
        <Route path="/changepassword" element={<Password />} />

      </Routes>
    <Footer/>

    </BrowserRouter>
  )
    
}

export default App
