import React from 'react'
import './navbar.css'
import Khaltilogo from'../images/khalti-logo.svg'

const Home = () => {
  return (
    <div>
      <div className='main__container__box'>
          <h2>SafaGhar DashBoard</h2>
          <ul>
              <li><h3>Total Waste Collected</h3>
              <h4>8tones</h4></li>
              <li><h3>Total Credit</h3>
              <h4>Rs 25,000</h4></li>
              <li><h3>Total Debit</h3>
              <h4>Rs 30,000</h4></li>
          </ul>

        </div>
        <div className='payment'>
          <img src={Khaltilogo} alt="khalti logo" onClick={()=>{alert('welcome to Khalti payment')}}/>
        </div>
    </div>
  )
}

export default Home
