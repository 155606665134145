import './navbar.css'
import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import Logo from '../images/logo.png'

import {BsPersonCircle} from 'react-icons/bs'
import links from '../data'




const Nabvar = () => {

const [show,setShow]=useState(false);

function handleShow(){
  setShow(!show);
}

  return (
    <>
      <div className="nav">
        <div className='nav__content'>
            <img src={Logo} className='logo'/>
        <div className='safaghar__btn' onClick={handleShow}>
        <BsPersonCircle/>
        <div className='showbox'>
        {
          show && <ul> {
            links.map(({name,path},index)=>{
              return(
                <li className='showlist' key={index}>
                  <Link to={path} className='text__container'>{name}</Link></li>
              )

            })
          }</ul>
        }
        </div>
    </div>
            
        </div>
      </div>
        
    </>
  )
}

export default Nabvar
