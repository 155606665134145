import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <div>
      <>
      <div className='main__footer'>
        <div className='footer__content'>
         Dashboard
        </div>
      </div>
    </>
    </div>
  )
}

export default Footer
