export const links = [
    {
      name: "Home",
      path: '/home'
    },
    {
      name: "Change Username",
      path: '/changeusername'
    },
    {
      name: "Change Password",
      path: '/changepassword'
    },
    {
      name: "Logout",
      path: '/logout'
    }
  ];
  
export default links